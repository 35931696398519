import './home.css';
const HomePage = () => {
	return (
		<section className="page">
			<h1 className='text-header'><span className=''>Hey, I'm</span>&nbsp;<span>Mithunraj</span></h1>
			{/* <h1 className='text-header'><span className='text-outline'>Hey, I'm</span>&nbsp;<span>Mithunraj</span></h1> */}
			<p>
				I'm a Full-Stack Web & Hybrid Mobile Application Developer, 
				<br />specializing in intuitive UI and seamless front-end experiences, 
				<br />with a flair for designing and prototyping UI/UX designs.
			</p>
		</section>
	)
}

export default HomePage