import { Outlet } from "react-router-dom";
import NavigationMenuComponent from "./components/navigationMenu/navigationMenu.component";
import { useState } from "react";

import './App.css';
// import AnimatedPage from "./components/animation/Animation.component";

function App() {
	const [showNavigationMenu, setShowNavigationMenu] = useState(false);
	const toggleMenuFunction = () => {
		setShowNavigationMenu(!showNavigationMenu);
	}
	const handleMenuSelection = () => {
		setShowNavigationMenu(false);
	}
	return (
		<>
			{/* <nav>
				<div>Logo</div>
				<div className='allow-cursor'>
					<svg className={showNavigationMenu? 'menu-close' : ''} xmlns="http://www.w3.org/2000/svg" 
						viewBox="0 0 48 48" width="32" height="32" onClick={toggleMenuFunction}>
						<title>Menu</title>
						<circle className='circle circle1' cx="12" cy="12" r="3"></circle>
						<circle className='circle circle2' cx="24" cy="12" r="3"></circle>
						<circle className='circle circle3' cx="36" cy="12" r="3"></circle>
						<circle className='circle circle4' cx="36" cy="24" r="3"></circle>
						<circle className='circle circle5' cx="36" cy="36" r="3"></circle>
						<circle className='circle circle6' cx="24" cy="36" r="3"></circle>
						<circle className='circle circle7' cx="12" cy="36" r="3"></circle>
						<circle className='circle circle8' cx="12" cy="24" r="3"></circle>
						<rect className='rect rect1' x="21" y="21" width="6px" height="6px" rx="3" ry="3"></rect>
						<rect className='rect rect2' x="21" y="21" width="6px" height="6px" rx="3" ry="3"></rect>
					</svg>
				</div>
			</nav> */}
			{/* <div className={showNavigationMenu ? 'expand' : 'collapse'}>
				<NavigationMenuComponent menuSelection={handleMenuSelection}></NavigationMenuComponent>
			</div> */}
			{/* <AnimatedPage> */}
				<Outlet />
			{/* </AnimatedPage> */}
		</>
	);
}

export default App;
