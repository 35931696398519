import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './index.css'

import AppPage from './App';
import HomePage from './pages/home/home';
import AboutPage from './pages/about/about';
import ContactPage from './pages/contact/contact';

const router = createBrowserRouter([
	{
		path: "/",
		element: <AppPage />,
		errorElement: <>Error Loading...</>,
		children: [
			{ path: '', element: <HomePage />, errorElement: <>Error Loading...</> },
			{ path: 'about', element: <AboutPage />, errorElement: <>Error Loading...</> },
			{ path: 'contact', element: <ContactPage />, errorElement: <>Error Loading...</> },
		]
	},
]);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);

